import decode from 'jwt-decode';
import { User } from '../types/user';

export interface IlJwt {
	email: string;
	'http://il/env': string;
	'http://il/grade': string;
	Organizations?: string;
	tenant?: string;
	role: string;
	'http://il/sites': string;
	'http://il/id': string;
	name: string;
	'http://il/products'?: { enabled: boolean; productName: string }[];
	'http://il/impersonation'?: { name: string; id: string }[];
	'http://il/sso'?: string;
	'http://il/username'?: string;
	'http://il/requestedProduct'?: string;
	'http://il/orgs'?: string;
	impersonated_by?: string;
}

export default function decodeJwt(jwt: string | any): User {
	const claims = decode(jwt) as IlJwt;
	const email = claims['email'];
	const environment = claims['http://il/env'];
	const grade = claims['http://il/grade'];
	const organizationIds = (claims['Organizations'] || '').split(',');
	const tenant = claims['tenant'];
	const role = claims['role'];
	const sites = claims['http://il/sites'];
	const studentId = claims['http://il/id'];
	const userName = claims['name'];
	const userProducts = (claims['http://il/products'] || [])
		.filter((p: { enabled: boolean }) => p.enabled)
		.map((p: { productName: string }) => p.productName);
	const impersonation = claims['http://il/impersonation'];
	const impersonatingUserId = claims.impersonated_by;
	const impersonatingUserName =
		impersonation && impersonation.length === 2 ? impersonation.find(user => user.id === impersonatingUserId)?.name : undefined;
	const impersonatedUserName =
		impersonation && impersonation.length === 2 ? impersonation.find(user => user.id !== impersonatingUserId)?.name : undefined;
	const ssoConnectionType = claims['http://il/sso'];
	const username = claims['http://il/username'];
	const requestedProduct = claims['http://il/requestedProduct'];
	const ilOrgs = (claims['http://il/orgs'] || '').split(','); //Added since the 'organizationIds' above are not really taking the orgs from the JWT. (?)

	return {
		email,
		environment,
		grade,
		organizationIds,
		tenant,
		role,
		sites,
		studentId,
		userName,
		userProducts,
		impersonatingUserId,
		impersonatingUserName,
		impersonatedUserName,
		ssoConnectionType,
		username,
		requestedProduct,
		ilOrgs
	};
}
