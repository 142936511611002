import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiServices } from '../constants/apiServices';
import { AnalyticsUser } from '../types/analyticsUser';
import http from './http';
import jwt from './jwt';
import { getEnvironment, getManagerUrl, getServiceVersion } from './network';
import { osVersion, osName } from 'react-device-detect';

const environment = getEnvironment();
const version = getServiceVersion(ApiServices.Manager, environment);
const baseUrl = `${getManagerUrl(environment)}/${version}`;

export function getAnalyticsUser(token: string): Observable<AnalyticsUser> {
	const user = jwt(token);
	const role = user.role.toUpperCase();

	// Currently organization info is only used for analytics,
	// so we can exlude ILAdmins and ILSiteAdmins
	if (/^IL/.test(role)) {
		return of({} as AnalyticsUser);
	}

	return http(`${baseUrl}/${role === 'STUDENT' ? 'students' : 'users'}/${user.studentId}?additionalfields=organizations`)
		.bearer(token)
		.get<AnalyticsUser>()
		.pipe(
			map(analyticsUser => {
				analyticsUser.sites = user.sites;
				analyticsUser.userProducts = user.userProducts;
				analyticsUser.userType = user.role;
				analyticsUser.osVersion = osName + osVersion;
				return analyticsUser;
			})
		);
}
