import { UrlMap } from '../types/urlMap';
import { VersionMap } from '../types/versionMap';
import { ApiServices } from './apiServices';
import { Environment } from './environments';

const URLS: UrlMap = {
	[Environment.Development]: {
		[ApiServices.Auth]: 'https://testapi.imaginelearning.com',
		[ApiServices.Manager]: 'https://testmanager.my.imaginelearning.com',
		[ApiServices.Authority]: 'https://testapi.imaginelearning.com',
		[ApiServices.Redirect]: 'https://localhost:5001/productSelection',
		[ApiServices.PostLogoutRedirect]: 'https://localhost:5001/signin',
		[ApiServices.ILU]: 'https://university.test.imaginelearning.com/',
		[ApiServices.Permissions]: 'https://testapi.imaginelearning.com',
		[ApiServices.Flagr]: 'https://admin.imlp-dev.edgenuityapp.com/api/flags/',
		[ApiServices.Logger]: 'https://testlogger.my.imaginelearning.com',
		[ApiServices.Imlp]: 'https://identity.qa.edgenuityapp.com/api',
		[ApiServices.Imlp2]: 'https://identity-qa.imaginelearning.com/api',
		[ApiServices.Import]: 'https://test.my.imaginelearning.com/import',
		[ApiServices.Caliper]: 'https://events.qa.edgenuityapp.com/api/caliper/v1/caliper/event',
		[ApiServices.ContextEnvironment]: 'http://edgenuity.com/events/',
		[ApiServices.CustomerToolsUI]: 'https://usermanagement.dev.imaginelearning.com/',
		[ApiServices.Registration]: 'https://registration-api.readiness-qa-app.wna.cloud/api/v2.2',
		[ApiServices.Maestro]: 'https://maestro.readiness-dev-app.wna.cloud'
	},
	[Environment.Test]: {
		[ApiServices.Auth]: 'https://testapi.imaginelearning.com',
		[ApiServices.Manager]: 'https://testmanager.my.imaginelearning.com',
		[ApiServices.Authority]: 'https://testapi.imaginelearning.com',
		[ApiServices.Redirect]: 'https://testapi.imaginelearning.com/productSelection',
		[ApiServices.PostLogoutRedirect]: 'https://testapi.imaginelearning.com/signin',
		[ApiServices.ILU]: 'https://university.test.imaginelearning.com/',
		[ApiServices.Permissions]: 'https://testapi.imaginelearning.com',
		[ApiServices.Flagr]: 'https://admin.imlp-qa.edgenuityapp.com/api/flags/',
		[ApiServices.Logger]: 'https://testlogger.my.imaginelearning.com',
		[ApiServices.Imlp]: 'https://identity.qa.edgenuityapp.com/api',
		[ApiServices.Imlp2]: 'https://identity-qa.imaginelearning.com/api',
		[ApiServices.Import]: 'https://test.my.imaginelearning.com/import',
		[ApiServices.Caliper]: 'https://events.qa.edgenuityapp.com/api/caliper/v1/caliper/event',
		[ApiServices.ContextEnvironment]: 'http://edgenuity.com/events/',
		[ApiServices.CustomerToolsUI]: 'https://usermanagement.qa.imaginelearning.com',
		[ApiServices.Registration]: 'https://registration-api.readiness-qa-app.wna.cloud/api/v2.2',
		[ApiServices.Maestro]: 'https://maestro.readiness-qa-app.wna.cloud'
	},
	[Environment.Rc]: {
		[ApiServices.Auth]: 'https://rcapi.imaginelearning.com',
		[ApiServices.Manager]: 'https://rcmanager.my.imaginelearning.com',
		[ApiServices.Authority]: 'https://rcapi.imaginelearning.com',
		[ApiServices.Redirect]: 'https://rcapi.imaginelearning.com/productSelection',
		[ApiServices.PostLogoutRedirect]: 'https://rcapi.imaginelearning.com/signin',
		[ApiServices.ILU]: 'https://university.test.imaginelearning.com/',
		[ApiServices.Permissions]: 'https://rcapi.imaginelearning.com',
		[ApiServices.Flagr]: 'https://admin.imlp-staging.edgenuityapp.com/api/flags/',
		[ApiServices.Logger]: 'https://rclogger.my.imaginelearning.com',
		[ApiServices.Imlp]: 'https://identity.staging.edgenuityapp.com/api',
		[ApiServices.Imlp2]: 'https://identity-staging.imaginelearning.com/api',
		[ApiServices.Import]: 'https://rc.my.imaginelearning.com/import/',
		[ApiServices.Caliper]: 'https://events.staging.edgenuityapp.com/api/caliper/v1/caliper/event',
		[ApiServices.ContextEnvironment]: 'http://edgenuity.com/events/',
		[ApiServices.CustomerToolsUI]: 'https://usermanagement.staging.imaginelearning.com',
		[ApiServices.Registration]: 'https://registration-api.readiness-staging-app.wna.cloud/api/v2.2',
		[ApiServices.Maestro]: 'https://maestro.readiness-staging-app.wna.cloud'
	},
	[Environment.Production]: {
		[ApiServices.Auth]: 'https://api.imaginelearning.com',
		[ApiServices.Manager]: 'https://manager.my.imaginelearning.com',
		[ApiServices.Authority]: 'https://api.imaginelearning.com',
		[ApiServices.Redirect]: 'https://api.imaginelearning.com/productSelection',
		[ApiServices.PostLogoutRedirect]: 'https://api.imaginelearning.com/signin',
		[ApiServices.ILU]: 'https://university.imaginelearning.com/',
		[ApiServices.Permissions]: 'https://api.imaginelearning.com',
		[ApiServices.Flagr]: 'https://identity.imaginelearning.com/api/flags/',
		[ApiServices.Logger]: 'https://logger.my.imaginelearning.com',
		[ApiServices.Imlp]: 'https://identity.app.edgenuity.com/api',
		[ApiServices.Imlp2]: 'https://identity.imaginelearning.com/api',
		[ApiServices.Import]: 'https://my.imaginelearning.com/import/',
		[ApiServices.Caliper]: 'https://events.imaginelearning.com/api/caliper/v1/caliper/event',
		[ApiServices.ContextEnvironment]: 'http://edgenuity.com/events/',
		[ApiServices.CustomerToolsUI]: 'https://usermanagement.imaginelearning.com',
		[ApiServices.Registration]: 'https://registration-api.readiness-prod-app.wna.cloud/api/v2.2',
		[ApiServices.Maestro]: 'https://maestro.readiness-prod-app.wna.cloud/'
	}
};

export default URLS;

export const LEGACY_APP = 'https://go.imaginelearning.com';

export const VERSIONS: VersionMap = {
	[Environment.Development]: {
		[ApiServices.Manager]: 'vLatest',
		[ApiServices.Permissions]: 'v1.0',
		[ApiServices.Logger]: 'v0.2',
		[ApiServices.ContextEnvironment]: '0-0-1'
	},
	[Environment.Test]: {
		[ApiServices.Manager]: 'v2.2',
		[ApiServices.Permissions]: 'v1.0',
		[ApiServices.Logger]: 'v0.2',
		[ApiServices.ContextEnvironment]: '0-0-1'
	},
	[Environment.Rc]: {
		[ApiServices.Manager]: 'v2.2',
		[ApiServices.Permissions]: 'v1.0',
		[ApiServices.Logger]: 'v0.2',
		[ApiServices.ContextEnvironment]: '0-0-1'
	},
	[Environment.Production]: {
		[ApiServices.Manager]: 'v2.2',
		[ApiServices.Permissions]: 'v1.0',
		[ApiServices.Logger]: 'v0.2',
		[ApiServices.ContextEnvironment]: '0-0-1'
	}
};
