import { throwError } from 'rxjs';
import http from './http';
import { getAuthUrl, getEnvironment, getImlp2Url, getImlpUrl, getManagerUrl, getSiteCode } from './network';
import { AuthSource } from '../constants/authSources';

export function getUsername(username: string, sitecode: string, isEducator: boolean = false) {
	const code = (sitecode || '').split('@').shift();
	return isEducator ? username : `${username}@${code}`;
}

export function lookupSiteCode(username: string) {
	const environment = getEnvironment();
	// This is staying on ILAuth for the moment. ReCAPTCHA have been removed but IMLP does not support sitecodelookup by user email yet.
	// We need to port it to IMLP and implement a replacement for ReCAPTCHA.
	const url = `${getAuthUrl(environment)}/v1.0/sitecodelookup`;
	return http(url)
		.body({ username })
		.header('Accept', 'application/json')
		.post<{ sitecodes: string[] }>();
}

export function requestOneTimeToken(encryptedtoken?: string, sitecode?: string) {
	if (!encryptedtoken) {
		return throwError(new Error('Missing parameter: encryptedtoken not specified'));
	}
	const environment = getEnvironment(sitecode);
	let url;
	if (getAuthProvider() === AuthSource.ILAuth) {
		url = getAuthUrl(environment);
	} else if (getAuthProvider() === AuthSource.IMLP2) {
		url = `${getImlp2Url(environment)}/transfer/initiate`;
	} else {
		url = `${getImlpUrl(environment)}/transfer/initiate`;
	}

	return http(url)
		.body('')
		.contentType('application/x-www-form-urlencoded')
		.bearer(encryptedtoken)
		.post<{ Code: string }>();
}

export function validateSiteCode(sitecode: string) {
	const baseSitecode = getSiteCode(sitecode);
	const environment = getEnvironment(sitecode);
	const url = `${getManagerUrl(environment)}/v2.2/sitecodes/` + baseSitecode;
	return http(url).get();
}

// Handle back-channel logouts
export function logoutCallback(url: string) {
	return http(url).get();
}

function selectAuthProvider(source: AuthSource) {
	if (source === AuthSource.ILAuth) {
		localStorage.setItem('oidcUseIlAuth', 'true');
		localStorage.removeItem('oidcUseIMLP2');
	} else if (source === AuthSource.IMLP2) {
		localStorage.setItem('oidcUseIMLP2', 'true');
		localStorage.removeItem('oidcUseIlAuth');
	} else {
		localStorage.removeItem('oidcUseIlAuth');
		localStorage.removeItem('oidcUseIMLP2');
	}
}

export function selectImlpProvider() {
	selectAuthProvider(AuthSource.IMLP);
}

export function selectImlp2Provider() {
	selectAuthProvider(AuthSource.IMLP2);
}

export function selectIlAuthProvider() {
	selectAuthProvider(AuthSource.ILAuth);
}

export function getAuthProvider() {
	if (localStorage.getItem('oidcUseIlAuth') === 'true') {
		return AuthSource.ILAuth;
	}
	if (localStorage.getItem('oidcUseIMLP2') === 'true') {
		return AuthSource.IMLP2;
	}

	return AuthSource.IMLP;
}

// Define the service
const Auth = {
	lookupSiteCode,
	requestOneTimeToken,
	validateSiteCode,
	logoutCallback,
	selectImlpProvider,
	selectImlp2Provider,
	selectIlAuthProvider,
	getAuthProvider
};

export default Auth;
