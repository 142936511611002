import { Log } from 'oidc-client/src/Log';
import { UserManager } from 'oidc-client/src/UserManager';
import { Environment } from '../constants/environments';
import URLS from '../constants/urls';
import {
	getAuthorityUrl,
	getImlp2Url,
	getImlpUrl,
	getInferredEnvironment,
	getPostLogoutRedirect,
	getRedirectUrl
} from '../services/network';
import { AuthSource } from '../constants/authSources';

export const scopes = [
	'api.transfer', // Need this to redeem and create one time tokens (session transfer fron Android and iOS)
	'api.assessment', // Need this to check lockout status, to control when user gets to L&L, and/or reader
	'api.manager', // Assessment makes manager API calls, and re-uses the bearer token for the user
	'api.permissions', // api.assessment depends on this scope.
	'openid' // Need this to get an id_token, so we can do a single sign out
];

export const imlpScopes = ['Scope.IL.Auth.Transfer', 'Scope.IL.Assessment', 'Scope.IL.Registration', 'Scope.IL.Permissions', 'openid'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDevOptions(environment?: Environment): any {
	const urls = environment && URLS[environment];
	return !urls
		? {}
		: {
				/* eslint-disable @typescript-eslint/camelcase */
				metadata: {
					// This block will help hit test auth.
					jwks_uri: `${urls.auth}/.well-known/openid-configuration/jwks`,
					issuer: urls.auth,
					authorization_endpoint: `${urls.auth}/connect/authorize`,
					token_endpoint: `${urls.auth}/connect/token`,
					end_session_endpoint: `${urls.auth}/connect/endsession`
				}
				/* eslint-enable */
		  };
}

function createDefaultUserManager() {
	const env = getInferredEnvironment(window.location.host);

	Log.logger = console;
	Log.level = env === Environment.Development ? Log.DEBUG : Log.ERROR;

	const devOptions = getDevOptions(process.env.REACT_APP_DEV_AUTH_ENV as Environment);

	/* eslint-disable @typescript-eslint/camelcase */
	const defaultOptions = {
		response_type: 'code', // We need both kinds of tokens to make api calls and also single signout
		loadUserInfo: false,
		authority: getAuthorityUrl(env),
		client_id: 'DirectLoginClientPKCE', // Hardcoded. We never hand-initiate a login unless it is this client so we are ok here?
		post_logout_redirect_uri: getPostLogoutRedirect(env),
		redirect_uri: getRedirectUrl(env),
		scope: scopes.join(' '),
		clockSkew: 11100 // in seconds (3h 5m). Default is 300s (5m). Token validation should account for client time or timezone being off +/- a few.

		// Uncomment for local dev proxy CORS fixes
		// run locally with the following CLI to get it to work:
		// HOST=lvh.me HTTPS=true yarn run start
		// If you want to run locally AND want to hit a local auth server, don't run with HTTPS.
		// metadata: { // This block will help hit test auth.
		// 	jwks_uri: 'https://lvh.me:5001/.well-known/openid-configuration/jwks',
		// 	issuer: 'https://testapi.imaginelearning.com',
		// 	authorization_endpoint: 'https://testapi.imaginelearning.com/connect/authorize',
		// 	token_endpoint: 'https://testapi.imaginelearning.com/connect/token',
		// 	end_session_endpoint: 'https://testapi.imaginelearning.com/connect/endsession'
		// }
		// metadata: { // This block will help hit a local auth.
		// 	jwks_uri: 'http://localhost:5000/.well-known/openid-configuration/jwks',
		// 	issuer: 'http://localhost:5000',
		// 	authorization_endpoint: 'http://localhost:5000/connect/authorize',
		// 	token_endpoint: 'http://localhost:5000/connect/token',
		// 	end_session_endpoint: 'http://localhost:5000/connect/endsession'
		// }
	};
	/* eslint-enable */

	const userManager = new UserManager({ ...defaultOptions, ...devOptions });
	return userManager;
}

function createImlpUserManager(authSource: AuthSource) {
	const env = getInferredEnvironment(window.location.host);
	const imlpUrl = authSource === AuthSource.IMLP ? getImlpUrl(env) : getImlp2Url(env);

	Log.logger = console;
	Log.level = env === Environment.Development ? Log.DEBUG : Log.ERROR;

	const devOptions = getDevOptions(process.env.REACT_APP_DEV_AUTH_ENV as Environment);

	/* eslint-disable @typescript-eslint/camelcase */
	const imlpDefaultOptions = {
		response_type: 'code', // We need both kinds of tokens to make api calls and also single signout
		loadUserInfo: false,
		authority: imlpUrl,
		client_id: 'DirectLoginClientPKCE',
		post_logout_redirect_uri: getPostLogoutRedirect(env),
		redirect_uri: getRedirectUrl(env),
		scope: imlpScopes.join(' '),
		staleStateAge: 900,
		monitorSession: false,
		clockSkew: 11100
	};
	/* eslint-enable */

	const userManager = new UserManager({ ...imlpDefaultOptions, ...devOptions });
	return userManager;
}

// Define the service
const UniversalLoginOidc = {
	createDefaultUserManager,
	createImlpUserManager
};

export default UniversalLoginOidc;
