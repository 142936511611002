import { parse } from 'bowser';

export enum UserAgentEnum {
	IOS = 'ios',
	KINDLE = 'kindle',
	ANDROID = 'android',
	HTML5 = 'html5'
}

export function parseUserAgent(): UserAgentEnum {
	const info = parse(window.navigator.userAgent);
	if (
		info.os?.name?.match(/ios/i) ||
		info.platform?.model?.match(/ipad/i) ||
		info.platform?.model?.match(/iphone/i) ||
		// iPadOS 13.4 and later report as Mac. Detect touch screen devices
		(info.os?.name?.match(/mac/i) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
	) {
		return UserAgentEnum.IOS;
	}
	if (/(amazon)/i.test(info.platform?.vendor ?? '') || /(kindle)/i.test(info.platform?.model ?? '')) {
		return UserAgentEnum.KINDLE;
	}
	if (/(android)/i.test(info.os?.name ?? '') || /(android)/i.test(info.browser?.name ?? '')) {
		return UserAgentEnum.ANDROID;
	}
	return UserAgentEnum.HTML5;
}
