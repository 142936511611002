import Url from 'url-parse';
import { AuthSource } from '../constants/authSources';
import { Environment } from '../constants/environments';
import { Products } from '../constants/products';
import { Dictionary } from '../types/dictionary';
import { UrlMap } from '../types/urlMap';
import { parseUrlPart } from './queryStringParameters';
import Auth from './auth';
import NATIVE_URLS from '../constants/nativeUrls';

export function getLaunchUrl(
	product: Products,
	urls: UrlMap,
	environment?: Environment | null,
	addQuery = true,
	queryStrings?: Dictionary<string>
) {
	const env = environment || Environment.Production;
	const queryString =
		queryStrings && Object.prototype.hasOwnProperty.call(queryStrings, product) ? decodeURIComponent(queryStrings[product]) : '';
	const queryParams = parseUrlPart(queryString);
	if (addQuery && (product === Products.ILE || product === Products.Spanish)) {
		queryParams.selectedProduct = product;
		queryParams.env = env;
	}

	queryParams.authSource = Auth.getAuthProvider();

	const baseUrl = urls[env][product];
	if (!baseUrl) {
		return undefined;
	}
	const url = new Url(baseUrl, true);
	url.set('query', {
		...url.query,
		...queryParams
	});
	return url.toString();
}

export function getNativeLaunchUrl(
	product: Products,
	urls: UrlMap,
	environment?: Environment | null,
	addQuery = true,
	queryStrings?: Dictionary<string>
) {
	const env = environment || Environment.Production;

	const baseUrl = NATIVE_URLS[env][product];
	if (!baseUrl) {
		return undefined;
	}
	const url = new Url(baseUrl, true);
	const queryString =
		queryStrings && Object.prototype.hasOwnProperty.call(queryStrings, product) ? decodeURIComponent(queryStrings[product]) : '';
	const queryParams = parseUrlPart(queryString);

	if (addQuery && product === Products.IEBenchmarkPlayerLink) {
		const redirectUrl = urls[env][Products.IEBenchmarkPlayerLink];
		url.query.redirecturl = encodeURIComponent(redirectUrl);
	}

	url.set('query', {
		...url.query,
		...queryParams
	});
	return url.toString();
}

export default getLaunchUrl;
