export enum Products {
	ILE = 'ILE',
	Spanish = 'Spanish',
	IM = 'IM',
	IMF = 'IMF',
	Reader = 'Reader',
	Galileo = 'Galileo',
	AdaptiveAssessment = 'AdaptiveAssessment',
	Lectura = 'Lectura',
	MyPathMath = 'MyPathMath',
	MyPathReading = 'MyPathReading',
	Science = 'Science',
	Purpose = 'Purpose',
	Traverse = 'Traverse',
	MyPathPortal = 'MyPathPortal',
	Robotify = 'Robotify',
	IPA = 'IPA',
	IEBenchmarkPlayerLink = 'IEBenchmarkPlayerLink', // Not a real product, but the IE Benchmark - Link Player Assessment
	DrSeuss = 'DrSeuss' // Product dependant on ILE. If ILE is enabled, DrSeuss will be enabled (data from Permissions API)
}

export enum LoggingProducts {
	ILE = 'LandL',
	Spanish = 'Espanol',
	Reader = 'Reader',
	Lectura = 'Lectura',
	Galileo = 'Galileo'
}
