import { createAction, createReducer } from '@reduxjs/toolkit';

/**
 * Action creators
 */

export const cameraChanged = createAction<'user' | 'environment' | undefined>('universal-login-page/webCam/WEBCAM_CAMERA_CHANGED');
export const camerasEnumerated = createAction<number>('universal-login-page/webCam/WEBCAM_CAMERAS_ENUMERATED');
export const closed = createAction('universal-login-page/webCam/WEBCAM_CLOSED');
export const error = createAction<string>('universal-login-page/webCam/WEBCAM_ERROR_OCCURRED');
export const loaded = createAction('universal-login-page/webCam/WEBCAM_LOADED');

/**
 * Reducer
 */

export interface WebCamState {
	enabled: boolean;
	error?: string;
	facingMode?: 'user' | 'environment';
	videoDeviceCount: number;
}

const initialState: WebCamState = {
	enabled: true,
	facingMode: 'user',
	videoDeviceCount: 0
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(error, (state, action) => ({
			...state,
			error: action.payload,
			enabled: false
		}))
		.addCase(camerasEnumerated, (state, action) => ({
			...state,
			videoDeviceCount: action.payload,
			facingMode: action.payload > 1 ? 'environment' : 'user',
			enabled: true
		}))
		.addCase(cameraChanged, (state, action) => ({
			...state,
			facingMode: action.payload
		}))
		.addCase(loaded, state => ({
			...state,
			enabled: true,
			error: ''
		}))
		.addCase(closed, state => ({
			...state,
			enabled: true,
			error: ''
		}))
);

export default reducer;
